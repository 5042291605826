import React from 'react'
import styled from 'styled-components'

export default ({ fg }) => {
  const color = fg ? fg : `#ffffff`

  return (
    <Wrap>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 161 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.48031 12.0988C7.16031 12.3588 6.72031 12.5788 6.18031 12.5788C5.52031 12.5788 5.08031 12.2787 5.08031 11.4587V6.63875H8.12031V4.31875H5.08031V1.67875H2.00031V4.31875H0.320312V6.63875H2.00031V11.4388C2.00031 12.5188 2.18031 13.2588 2.62031 13.8988C3.24031 14.8188 4.30031 15.2388 5.66031 15.2388C6.92031 15.2388 7.88031 14.8587 8.62031 14.2987L7.48031 12.0988Z"
          fill={color}
        />
        <path
          d="M13.1484 14.9988V8.91875C13.1484 7.53875 14.0884 6.79875 15.2284 6.79875C16.5884 6.79875 16.8884 7.71875 16.8884 8.61875V14.9988H19.9484V7.91875C19.9484 5.75875 18.7684 4.07875 16.3484 4.07875C15.1284 4.07875 13.9884 4.59875 13.1484 5.53875V0.71875H10.0884V14.9988H13.1484Z"
          fill={color}
        />
        <path
          d="M32.7736 9.53875C32.7736 6.31875 30.7536 4.07875 27.3536 4.07875C23.8136 4.07875 21.7536 6.49875 21.7536 9.71875C21.7536 12.8588 23.7336 15.2388 27.5536 15.2388C29.8536 15.2388 31.4536 14.3988 32.6336 13.2188L30.6736 11.3787C29.9736 12.1587 28.9536 12.6388 27.7336 12.6388C26.1936 12.6388 25.1336 11.9188 25.0336 10.4588H32.7136C32.7336 10.2188 32.7736 9.87875 32.7736 9.53875ZM25.0536 8.37875C25.1536 7.15875 26.0736 6.35875 27.4136 6.35875C28.6736 6.35875 29.6336 7.03875 29.7536 8.37875H25.0536Z"
          fill={color}
        />
        <path
          d="M45.2792 12.8787V4.31875H42.1992V5.53875C41.3792 4.57875 40.2592 4.07875 38.8992 4.07875C35.9992 4.07875 34.0592 6.35875 34.0592 9.23875C34.0592 12.0988 35.9792 14.3388 38.8792 14.3388C40.2192 14.3388 41.3792 13.8588 42.1992 12.8988V13.6388C42.1992 15.4588 40.7992 16.1188 39.0192 16.1188C37.6392 16.1188 36.3992 15.7188 35.5392 15.1388L34.4192 17.5188C35.7392 18.2988 37.2592 18.6788 39.0792 18.6788C40.8792 18.6788 42.8192 18.3188 44.0592 16.9588C45.0192 15.8988 45.2792 14.5787 45.2792 12.8787ZM42.2192 9.19875C42.2192 10.6788 41.2992 11.8388 39.7192 11.8388C38.1192 11.8388 37.2992 10.6788 37.2992 9.19875C37.2992 7.71875 38.1192 6.59875 39.7192 6.59875C41.3392 6.59875 42.2192 7.73875 42.2192 9.19875Z"
          fill={color}
        />
        <path
          d="M54.4837 4.31875V10.3988C54.4837 11.7788 53.5438 12.5188 52.4038 12.5188C51.0238 12.5188 50.7438 11.5988 50.7438 10.6988V4.31875H47.6838V11.3988C47.6838 13.5588 48.8637 15.2388 51.2837 15.2388C52.5237 15.2388 53.6437 14.6987 54.4837 13.7987V14.9988H57.5438V4.31875H54.4837Z"
          fill={color}
        />
        <path
          d="M63.1289 14.9988V4.31875H60.0689V14.9988H63.1289ZM63.1289 2.99875V0.71875H60.0689V2.99875H63.1289Z"
          fill={color}
        />
        <path
          d="M76.2348 14.9988V0.71875H73.1548V5.53875C72.2948 4.55875 71.1948 4.07875 69.8948 4.07875C66.8948 4.07875 65.0348 6.47875 65.0348 9.65875C65.0348 12.8188 66.8948 15.2388 69.8948 15.2388C71.1748 15.2388 72.2948 14.7588 73.1548 13.7788V14.9988H76.2348ZM73.1548 9.67875C73.1548 11.2788 72.3348 12.5988 70.6748 12.5988C69.0348 12.5988 68.2548 11.2788 68.2548 9.67875C68.2548 8.07875 69.0348 6.71875 70.6748 6.71875C72.3148 6.71875 73.1548 8.05875 73.1548 9.67875Z"
          fill={color}
        />
        <path
          d="M89.1603 9.53875C89.1603 6.31875 87.1403 4.07875 83.7403 4.07875C80.2003 4.07875 78.1403 6.49875 78.1403 9.71875C78.1403 12.8588 80.1203 15.2388 83.9403 15.2388C86.2403 15.2388 87.8403 14.3988 89.0203 13.2188L87.0603 11.3787C86.3603 12.1587 85.3403 12.6388 84.1203 12.6388C82.5803 12.6388 81.5203 11.9188 81.4203 10.4588H89.1003C89.1203 10.2188 89.1603 9.87875 89.1603 9.53875ZM81.4403 8.37875C81.5403 7.15875 82.4603 6.35875 83.8003 6.35875C85.0603 6.35875 86.0203 7.03875 86.1403 8.37875H81.4403Z"
          fill={color}
        />
        <path
          d="M105.997 14.9988V0.71875H102.917V5.53875C102.057 4.55875 100.957 4.07875 99.6566 4.07875C96.6566 4.07875 94.7966 6.47875 94.7966 9.65875C94.7966 12.8188 96.6566 15.2388 99.6566 15.2388C100.937 15.2388 102.057 14.7588 102.917 13.7788V14.9988H105.997ZM102.917 9.67875C102.917 11.2788 102.097 12.5988 100.437 12.5988C98.7966 12.5988 98.0166 11.2788 98.0166 9.67875C98.0166 8.07875 98.7966 6.71875 100.437 6.71875C102.077 6.71875 102.917 8.05875 102.917 9.67875Z"
          fill={color}
        />
        <path
          d="M118.922 9.53875C118.922 6.31875 116.902 4.07875 113.502 4.07875C109.962 4.07875 107.902 6.49875 107.902 9.71875C107.902 12.8588 109.882 15.2388 113.702 15.2388C116.002 15.2388 117.602 14.3988 118.782 13.2188L116.822 11.3787C116.122 12.1587 115.102 12.6388 113.882 12.6388C112.342 12.6388 111.282 11.9188 111.182 10.4588H118.862C118.882 10.2188 118.922 9.87875 118.922 9.53875ZM111.202 8.37875C111.302 7.15875 112.222 6.35875 113.562 6.35875C114.822 6.35875 115.782 7.03875 115.902 8.37875H111.202Z"
          fill={color}
        />
        <path
          d="M130.168 11.5988C130.168 9.37875 128.308 8.61875 125.788 8.19875C124.448 7.97875 123.428 7.89875 123.428 7.21875C123.428 6.71875 124.128 6.41875 124.988 6.41875C126.428 6.41875 127.568 7.01875 128.248 7.71875L130.068 6.09875C129.128 5.09875 127.408 4.07875 124.988 4.07875C122.528 4.07875 120.488 5.19875 120.488 7.49875C120.488 9.83875 122.368 10.5788 124.888 10.9188C126.188 11.0988 127.168 11.2388 127.168 11.9988C127.168 12.5388 126.548 12.8787 125.448 12.8787C124.188 12.8787 122.748 12.4188 121.948 11.2588L119.868 12.6388C120.968 14.4188 122.948 15.2388 125.268 15.2388C128.288 15.2388 130.168 13.8188 130.168 11.5988Z"
          fill={color}
        />
        <path
          d="M135.137 14.9988V4.31875H132.077V14.9988H135.137ZM135.137 2.99875V0.71875H132.077V2.99875H135.137Z"
          fill={color}
        />
        <path
          d="M148.303 12.8787V4.31875H145.223V5.53875C144.403 4.57875 143.283 4.07875 141.923 4.07875C139.023 4.07875 137.083 6.35875 137.083 9.23875C137.083 12.0988 139.003 14.3388 141.903 14.3388C143.243 14.3388 144.403 13.8588 145.223 12.8988V13.6388C145.223 15.4588 143.823 16.1188 142.043 16.1188C140.663 16.1188 139.423 15.7188 138.563 15.1388L137.443 17.5188C138.763 18.2988 140.283 18.6788 142.103 18.6788C143.903 18.6788 145.843 18.3188 147.083 16.9588C148.043 15.8988 148.303 14.5787 148.303 12.8787ZM145.243 9.19875C145.243 10.6788 144.323 11.8388 142.743 11.8388C141.143 11.8388 140.323 10.6788 140.323 9.19875C140.323 7.71875 141.143 6.59875 142.743 6.59875C144.363 6.59875 145.243 7.73875 145.243 9.19875Z"
          fill={color}
        />
        <path
          d="M153.867 14.9988V8.91875C153.867 7.53875 154.807 6.79875 155.947 6.79875C157.307 6.79875 157.607 7.71875 157.607 8.61875V14.9988H160.667V7.91875C160.667 5.75875 159.487 4.07875 157.067 4.07875C155.847 4.07875 154.707 4.59875 153.867 5.53875V4.31875H150.807V14.9988H153.867Z"
          fill={color}
        />
        <circle cx="92" cy="13.1172" r="2" fill="#35BCAA" />
      </svg>
    </Wrap>
  )
}

const Wrap = styled.span`
  display: block;
  width: 181px;
  position: relative;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    width: 161px;
    top: 2px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: 161px;
  }
`
