import React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import { ButtonLink } from '../page/components'
import LogomarkHorizontal from '../svg/logomark-horizontal'
import GridMarks from './grid-marks'
import useWindowSize from '@rehooks/window-size'
import HeaderMobile from './header-mobile'

export default ({ hideLogo }) => {
  const windowSize = useWindowSize()
  if (!windowSize) return null
  const { innerWidth } = windowSize

  if (innerWidth <= 900) {
    return <HeaderMobile />
  } else {
    const logo =
      hideLogo === true ? null : (
        <Link to="/">
          <LogomarkHorizontal />
        </Link>
      )
    return (
      <Header>
        <GridMarks />
        <Flex flexWrap="nowrap" width={['100%']}>
          <Box width={[1, 8 / 12]} display="flex" alignItems="center">
            {logo}
          </Box>
          <Box
            width={[1, 4 / 12]}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <Links>
              <Link to="/faq">FAQ</Link>
              <Link to="/support">Support</Link>
              <ButtonLink>
                <Link to="/sign-in">Sign In</Link>
              </ButtonLink>
            </Links>
          </Box>
        </Flex>
      </Header>
    )
  }
}

const Header = styled.div`
  height: 100px;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  align-items: center;
  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    height: 76px;
    padding-left: 35px;
    padding-right: 35px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-left: 14px;
    padding-right: 14px;
  }
`

const Links = styled.div`
  a {
    margin-left: 1.5em;
  }
`
