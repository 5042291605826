import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'

const Seo = ({ title }) => {
  return (
    <Helmet>
      <title>{`${title} - ${config.siteTitle}`}</title>
      <meta
        name="google-site-verification"
        content="sg74inu3b2a9_jQhpJVCjJlom9daF79YSGoTzqsxZEc"
      />
    </Helmet>
  )
}

export default Seo
