import React from 'react'
import theme from '../../styles/theme'

const IconHamburger = () => {
  const fill = theme.colors.white
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.792 2.38477V0.644531H0V2.38477H14.792Z" fill={fill} />
      <path d="M14.792 8.74023V7H0V8.74023H14.792Z" fill={fill} />
      <path d="M14.792 15.0957V13.3555H0V15.0957H14.792Z" fill={fill} />
    </svg>
  )
}

export default IconHamburger
