import React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
// import { HideMob } from '../page/components'

export default () => {
  const d = new Date()
  const y = d.getFullYear()
  return (
    <Footer>
      <Flex flexWrap="nowrap">
        <Box width={[1, 9 / 12]}>
          <Links>
            <span>&copy;{y} Shillington</span>
            <Link to="/terms">Terms of Use</Link>
          </Links>
        </Box>
      </Flex>
    </Footer>
  )
}

const Footer = styled.div`
  padding-top: 170px;
  padding-bottom: 37px;
  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 100px;
    padding-bottom: 32px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 50px;
    padding-bottom: 22px;
  }
`

const Links = styled.div`
  a,
  span {
    margin-right: 1.5em;
  }
`
