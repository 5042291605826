import React from 'react'
import styled from 'styled-components'

export default ({ bg }) => {
  return (
    <Grid>
      <GridInner>
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
      </GridInner>
    </Grid>
  )
}

const Grid = styled.span`
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  padding-left: 60px;
  padding-right: 60px;
  display: none;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-left: 14px;
    padding-right: 14px;
  }
`

const GridInner = styled.span`
  display: block;
  display: flex;
  flex-wrap: nowrap;
  background: transparent;
  height: 5px;
`

const Nib = styled.span`
  width: ${(1 / 10) * 100}%;
  position: relative;
  &:after {
    content: ' ';
    display: block;
    width: 2px;
    left: -1px;
    position: relative;
    height: 10px;
    background: ${props => (props.bg ? props.bg : `#6d6d6d`)};
    @media screen and (max-width: ${props => props.theme.responsive.sm}) {
      display: ${props => (props.isAlt ? `none` : 'block')};
    }
  }
  &:last-child {
    &:before {
      content: ' ';
      display: block;
      width: 2px;
      position: relative;
      height: 10px;
      background: ${props => (props.bg ? props.bg : `#6d6d6d`)};
      position: absolute;
      right: -1px;
      top: 0;
    }
  }
`

/*
import React from 'react'
import styled from 'styled-components'

export default ({ bg }) => {
  return (
    <Grid>
      <GridInner>
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
        <Nib bg={bg} />
        <Nib bg={bg} isAlt />
      </GridInner>
    </Grid>
  )
}

const Grid = styled.span`
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  padding-left: 60px;
  padding-right: 60px;
  display: block;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const GridInner = styled.span`
  display: block;
  display: flex;
  flex-wrap: nowrap;
  background: transparent;
  height: 5px;
`

const Nib = styled.span`
  width: ${(1 / 12) * 100}%;
  position: relative;
  &:after {
    content: ' ';
    display: block;
    width: 3px;
    height: 5px;
    background: ${props => (props.bg ? props.bg : `#6d6d6d`)};
    @media screen and (max-width: ${props => props.theme.responsive.sm}) {
      display: ${props => (props.isAlt ? `none` : 'block')};
    }
  }
  &:last-child {
    &:before {
      content: ' ';
      display: block;
      width: 3px;
      height: 5px;
      background: ${props => (props.bg ? props.bg : `#6d6d6d`)};
      position: absolute;
      right: -2px;
      top: 0;
    }
  }
`
*/
