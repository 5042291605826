import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Copy, ButtonLink } from '../page/components'

export default ({ isAccount }) => {
  return (
    <Flex flexWrap="nowrap">
      <Box width="100%">
        <Copy>
          <h3>Technical Issues</h3>
          <p>
            Report a technical issue regarding The Guide. Please note that a
            response typically takes between 24-48hours.
          </p>
          <ButtonLink>
            <a href="mailto:theguide@shillingtoneducation.com">
              Report a technical issue
            </a>
          </ButtonLink>
          <hr />
        </Copy>

        <Copy>
          <h3>Content Suggestion</h3>
          <p>
            Did you find something that needs updating or have an idea for an
            addition? Get in contact and let us know.
          </p>
          <ButtonLink>
            <a href="mailto:theguide@shillingtoneducation.com">
              Make a content suggestion
            </a>
          </ButtonLink>
          <hr />
        </Copy>

        <Copy>
          <p>
            Please visit our{' '}
            <Link to={isAccount ? `/account-faq` : `/faq`}>FAQ</Link> for any
            queries. For all other issues or questions please contact{' '}
            <a href="mailto:info@shillingtoneducation.com">
              info@shillingtoneducation.com
            </a>
          </p>
        </Copy>

        {/* <Flex flexWrap="wrap" mb={[4, 4, 5]}>
          <Box width={[1 / 2, 1 / 3]} pr={3}>
            <SupportBlock>
              <Copy>
                <h3>New York</h3>
                <p>
                  L6, 315 Madison Ave
                  <br />
                  New York, NY 10017
                  <br />
                  +1 (212) 682 6611
                </p>
                <ButtonLink>
                  <a href="mailto:us@shillingtoneducation.com">Contact</a>
                </ButtonLink>
              </Copy>
            </SupportBlock>
          </Box>
          <Box width={[1 / 2, 1 / 3]} pr={3}>
            <SupportBlock>
              <Copy>
                <h3>London</h3>
                <p>
                  L3, Bury House, 31 Bury St
                  <br />
                  London EC3A 5AR
                  <br />
                  +44 (0) 207 621 1778
                </p>
                <ButtonLink>
                  <a href="mailto:uk@shillingtoneducation.com">Contact</a>
                </ButtonLink>
              </Copy>
            </SupportBlock>
          </Box>
          <Box width={[1 / 2, 1 / 3]} pr={3}>
            <SupportBlock>
              <Copy>
                <h3>Manchester</h3>
                <p>
                  L5, 1 Portland St
                  <br />
                  Manchester M1 3BE
                  <br />
                  +44 (0) 161 237 3063
                </p>
                <ButtonLink>
                  <a href="mailto:uk@shillingtoneducation.com">Contact</a>
                </ButtonLink>
              </Copy>
            </SupportBlock>
          </Box>
          <Box width={[1 / 2, 1 / 3]} pr={3}>
            <SupportBlock>
              <Copy>
                <h3>Sydney</h3>
                <p>
                  L3, 50 Margaret St
                  <br />
                  Sydney NSW 2000
                  <br />
                  +61 2 9299 1166
                </p>
                <ButtonLink>
                  <a href="mailto:au@shillingtoneducation.com">Contact</a>
                </ButtonLink>
              </Copy>
            </SupportBlock>
          </Box>
          <Box width={[1 / 2, 1 / 3]} pr={3}>
            <SupportBlock>
              <Copy>
                <h3>Melbourne</h3>
                <p>
                  L8, 276 Flinders St
                  <br />
                  Melbourne VIC 3000
                  <br />
                  +61 3 9663 8444
                </p>
                <ButtonLink>
                  <a href="mailto:au@shillingtoneducation.com">Contact</a>
                </ButtonLink>
              </Copy>
            </SupportBlock>
          </Box>
          <Box width={[1 / 2, 1 / 3]} pr={3}>
            <SupportBlock>
              <Copy>
                <h3>Brisbane</h3>
                <p>
                  L7, 157 Ann St
                  <br />
                  Brisbane QLD 4000
                  <br />
                  +61 7 3839 9966
                </p>
                <ButtonLink>
                  <a href="mailto:au@shillingtoneducation.com">Contact</a>
                </ButtonLink>
              </Copy>
            </SupportBlock>
          </Box>
        </Flex> */}
      </Box>
    </Flex>
  )
}

const SupportBlock = styled.div`
  margin-bottom: 2em;
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
  }
`
